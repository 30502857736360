const config = {
  prod: false,
  debug: false,
  // proxy: "https://cors-anywhere.herokuapp.com/",
  //proxy: "https://obscure-sea-01598.herokuapp.com/",
  proxy: "",
  apiUrl() {
    if (this.prod) {
      return "https://www.sawcalc.com/api/Service.svc/";
    } else {
      return "https://devsawcalc.sbdinc.com/api/Service.svc/";
      //return "http://localhost:8080/api/Service.svc/";
    }
  },
  url() {
    const debugUrl = this.proxy + this.apiUrl();
    if (this.debug) {
      return debugUrl;
    } else {
      const debug = window.location.href.startsWith("http://localhost");
      if (debug) {
        return debugUrl;
      } else {
        return this.apiUrl();
      }
    }
  },
  dbSyncSeconds() {
    if (this.prod) {
      return 86400;
    } else {
      return 14400;
    }
  },
};

export { config };
